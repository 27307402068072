.pages {
	position: relative;
}

.page-header {
	position: absolute;
	width: 100%;
}

.page-padding { pointer-events: none; }

.page-nav
{
	display: flex;
	justify-content: center;
	flex-shrink: 0;
	position: absolute;
	width: 100%;
	background-color: var(--background);

	ul {
		position: relative;
		width: 100%;
		display: flex;
		justify-content: space-evenly;
		margin: 0;
		padding: 0;

		li {
			display: flex;
			flex: 1 1 0px;
			justify-content: center;
		}
		.pill {
			position: absolute;
			height: 2px;
			background-color: white;
			bottom: 0;
		}
	}
}

.page-content {
	width: 100%;
	max-width: 100%;
	position: relative;
	display: flex;

	overflow-x: auto;
	scroll-snap-type: x mandatory;
	
	.page {
		scroll-snap-align: start;
		min-width: 100%;
		overflow-y: hidden;	
		&.active {
			overflow-y: auto;	
		}
	}




	&::-webkit-scrollbar { width: 0px; height: 0px; }
	&::-webkit-scrollbar-button { width: 0; height: 0; }
	&::-webkit-scrollbar-thumb { background: transparent; border: 0px; }
	&::-webkit-scrollbar-thumb:hover { background: transparent; }
	&::-webkit-scrollbar-thumb:active { background: transparent; }
	&::-webkit-scrollbar-track { background: transparent; border: 0; }
	&::-webkit-scrollbar-track:hover { background: transparent; }
	&::-webkit-scrollbar-track:active { background: transparent; }
	&::-webkit-scrollbar-corner { background: transparent; }  

	&:hover {
		&::-webkit-scrollbar-thumb { background: transparent; }
	}
}

.athlete-pages
{
	display: flex;
	flex-direction: column;
	overflow-y: hidden;
	height: 100%;

	background-color: var(--background);

	.page-nav { 
		text-transform: uppercase; 
		font-weight: bold;
		color: var(--subtext);

		ul {
			max-width: 500px;
		}

		.pill { background-color: var(--pto-red); height: 3px; }
		li {
			cursor: pointer;
			padding: 8px 4px;
			transition: color 250ms;
			&.active {
				color: var(--pto-red);
			}
			&:hover {
				color: var(--pto-red);
			}
		}
	}
	.page-content
	{
		flex-shrink: 1;
		//overflow-y: auto;
	}
}