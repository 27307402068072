.ql-snow {
	.ql-stroke { stroke: white; }
	.ql-fill { fill: white; }
	.ql-picker { color: white; }
	.ql-picker-options { background-color: #333;  }

	.ql-mention-list { 
		margin: 0; 
		list-style: none; 
		padding: 8px; 
		border: 1px solid rgba(255,255,255,0.5); 

		.ql-mention-list-item { 
			padding: 4px 8px; 
			padding-right: 32px;
			cursor: pointer; 
			&.selected {
				background-color: #D10B0C;
			}
		}

	}
}
