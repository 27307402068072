$grid-gutter-width: 10px !default;
$text-color: #f8f9fa !default;
$table-color: #f8f9fa !default;
$nav-pills-link-active-bg: transparent !default;
$nav-pills-border-radius: 24px;
@import "~bootstrap/scss/bootstrap";
@import "quill/dist/quill.snow.css";

@import "./css/ql";
@import "./css/datepicker";
@import "./scrollbars";
@import "./page";

$pto-white: #ffffff;
$pto-black: #000000;
$pto-red: #D10B0C;
$pto-darkblue: #0a0a1e;
$pto-blue: #10102F;
$pto-lightblue: #1a1a4c;

$background: #18191a;
$section-dark: #212121;
$section-light: #202122;
$section: #28292a;
$subsection: #38393a;
$bordercolor: rgba(255,255,255,0.1);
$text: #f8f9fa; 
$head-nav: #1c1d1e;

$background: #28292a;
$section-dark: #28292a;
$head-nav: #303132;

$background: #fafafa;

$background: #10102F;

//$text: #181818; 
//$section: #e8e8e8;
$subsection: #333;
/*$section-light: #c8c8c8;
$section-dark: #f8f8f8;
*/
//$bordercolor: rgba(0,0,0,0.1);


$box-shadow-color: rgba(0,0,0,0.2);



$background: #111;
$text: #f8f9fa; 
$box-shadow-color: rgba(0,0,0,0.5);


$background: #ffffff;
$text: #272323; 
$section: #ddd;
$subsection: #ffffff;
$head-nav: #ffffff;
$bordercolor: rgba(0,0,0,0.1);
$section-light: #ffffff;




$topbar-height: 44px;
$footer-height: 60px;
$footer-margin: 40px;

$palette0: #f44336;
$palette1: #e91e63;
$palette2: #9c27b0; 
$palette3: #673ab7; 
$palette4: #3f51b5; 
$palette5: #2196f3; 
$palette6: #03a9f4; 
$palette7: #00bcd4; 
$palette8: #009688; 
$palette9: #4caf50;
$palette10: #8bc34a; 
$palette11: #cddc39; 
$palette12: #ffeb3b; 
$palette13: #ffc107; 
$palette14: #ff9800; 
$palette15: #ff5722; 
$palette16: #795548; 
$palette17: #9e9e9e; 
$palette18: #607d8b; 
$palette19: #ffffff;


$swim-color: #03a9f4;
$bike-color: #fecc00;
$run-color: #ff9800;
$overall-color: #4caf50;

html {
	--background: #28292a;
	--text: #f8f9fa;
	--subtext: #888888;

	--border-color: rgba(255,255,255,0.1);

	--card-color: #38393a;

	--subsection: #282828;
	--sectionlight: #303030;
	--select-color: #282828;

	--info-bottom: #222;
	--white: #ffffff;

	--ring-color: #444;

	--pto-red: #D10B0C;

	//--background: hsl(160deg 10% 9%);
	//--subsection: hsl(160deg 10% 12%);
	//--info-bottom: hsl(160deg 10% 13%);

}

.card {
	display: block;
	color: white;
	background-color: var(--card-color);
	padding: 12px 16px;
	border-radius: 4px;
	margin-bottom: 12px;
	box-shadow: 0 1px 10px 0 rgb(0 0 0 / 5%);
}


#app {
	flex-shrink: 1;
	flex-grow: 1;
	height: 100%;
	//width: 100%;
	display: flex;
	//max-height: -webkit-fill-available;

	//background-color: green;
	
	//padding-bottom: env(safe-area-inset-bottom);


	nav { 
		width: 180px; 
		display: flex;
		flex-direction: column;
		font-weight: bold;
		background-color: var(--card-color); //hsl(360deg 90% 43% / 3%);
		flex-shrink: 0;
		padding: 0;
		margin: 0;
		border-radius: 0;
		border: 0;
		.item {
			//transition: color 250ms, background-color 250ms;
			cursor: pointer;
			padding: 8px 12px;

			i, svg { 
				padding-right: 8px;
				width: 24px;
				height: min-content;
				display: inline-flex;
				justify-content: center; 
			}

			&:hover { 
				color: hsl(360deg 90% 43%);
				background-color: hsl(360deg 90% 43% / 10%);

				svg path {
					fill: hsl(360deg 90% 43%);
				}
			}
			&.selected { background-color: var(--pto-red); color: white; }
		}
	}
	main { flex-grow: 1; overflow-y: auto; padding: 12px; /*padding-bottom: 40px;*/ display: flex; }

	//margin-bottom: constant(safe-area-inset-bottom);
	//margin-bottom: env(safe-area-inset-bottom);

	&.margin-top{
		//margin-top: $topbar-height;  
		//height: calc(100% - 44px);
		padding-top: $topbar-height;  
		height: calc(100%);
	}


}

input[type="time"]::-webkit-calendar-picker-indicator{
	filter: invert(100%);
 }

.athletes-table
{
	td, th { padding: 4px 8px; text-align: center; }
	td:nth-child(2), th:nth-child(2) { text-align: left; }
}


@font-face {
	font-family: Avenir Bold;
	src: url(/assets/fonts/AvenirNext.woff) format("woff")
}

.no-pointer-event {
	pointer-events: none;
}

.headline { 
	font-family: Avenir Bold,sans-serif;
	text-transform: uppercase;

	img {
		height: 36px;
		margin-right: 10px;
		padding-block: 5px 11px;
	}

	&.italic{
		font-family: Avenir Next LT W04 Heavy Cn It,sans-serif;
	}
}

.toolbar {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
}


.btn-dark {
	background-color: #303030;

	&:hover {
		background-color: var(--pto-red);
	}
}

.form-control,
.form-control-sm {
	background: var(--select-color); 
	border-color: var(--border-color);
	color: var(--text);

	&:focus {
		background: #282828; 
		color: var(--text);
	}

	&[readonly],
	&[disabled] {
		background: var(--select-color);
		border-color: var(--border-color);
		color: var(--text);
		opacity: .5;

		&:hover {
			cursor: not-allowed;
		}
	}
}

input {
	&:focus:invalid { background-color: $pto-red; }
	&:invalid { background-color: $pto-red; }
}

.zindex1 {
	z-index: 1;
}

.nav-link { 
	color: var(--subtext);
	&:hover { color: var(--text); background-color: var(--subsection) !important; }
}

@font-face {
    font-family: Avenir Next LT W04 Heavy Cn It;
    src: url(/assets/fonts/avenir.woff2) format("woff2"),url(/assets/fonts/avenir.woff) format("woff")
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
	text-transform: uppercase;
	font-weight: bold;
}

.pto-red { color: $pto-red; }

html {
	font-size: 14px;
	scroll-behavior: smooth;
}

body {
	background-color: var(--background);
	color: var(--text);
	overscroll-behavior: none;
	position: relative;
	margin: 0;
	padding: 0;
	height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
	max-height: -webkit-fill-available;
	font-family: 'Lato', sans-serif;
	overflow: hidden;

	&.yscroll { 
		overflow-y: auto;
		max-height: unset;
	}

	&.noscroll { position: static; overflow: hidden; height: 100%; }
}

.content-area {
	padding-top: $topbar-height;
	max-height: 100%;
}

body.yscroll .content-area {
	max-height: unset;
}

@media (max-width: 768px) {
	h1 { font-size: 1.5rem !important; }
}

#athlete-popover {
	display: flex;
	top: 0;
	left: 0;
	position: absolute;
	width: fit-content;
	padding: 8px;
	background-color: var(--subsection);
	box-shadow: 0 0 16px $box-shadow-color;
	border-radius: 8px;
	z-index: 10;

	visibility: hidden;

	transition: opacity 250ms, visibility 250ms;
	opacity: 0;

	&.active {
		opacity: 1;
		visibility: unset;
	}

	.athlete-pic {
		width: 80px;
		height: 80px;
	}
	h1 { font-size: 20px; }

	.attribute-group { 
		font-size: 12px; 
		//flex-wrap: wrap;
		justify-content: center;
		.attribute { flex-shrink: 0; }
	}


	.info-block {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		h1 { text-align: center; }
	}
	.ring_progress { width: 50px; height: 60px; }
	.social { margin-top: 1rem !important; }
}

.dotsep:before {
	content: " \00b7";
	margin: 0 8px; 
	text-decoration: none !important;
}

.border-4 { border-radius: 4px; }
.text-grey { color: #888; }
.rotate-sm {
	@media (max-width: 768px) {
		transform: rotate(-90deg);
	}
}
.section {
	//background-color: $section-dark;
	//background-color: #1c1d1e;
	border-radius: 6px;
	padding: 16px 16px;
	margin-bottom: 10px;
	width: 100%;
	border: 1px solid var(--border-color);
}

.section-bottom
{
	padding: 0 10px;
	border-bottom: 1px solid var(--border-color);
	padding-bottom: 16px;
	margin-bottom: 16px;
}

.hide-if-empty:empty { display: none !important; }

.select {
	position: relative;
	select {
		color: white;
		background-color: var(--select-color) !important;
		appearance: none;
		-webkit-appearance: none;
		padding-right: 24px;
	}
	&:after {
		pointer-events: none;
		font-family: 'Material Icons Round';
		font-weight: normal;
		font-style: normal;
		content: "keyboard_arrow_down";
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		font-size: 16px;
		width: 16px;
		height: 100%;
		top: 0;
		right: 4px;
	}
}


.table { 
	margin-bottom: unset;

	td {
		border-color: var(--border-color);
		vertical-align: middle;
	}

	th {
		border: 0; 
		color: white;
		font-weight: unset; 
		vertical-align: middle;
	}

	.table-sort { 
		cursor: pointer; 
		&:hover { text-decoration: underline; }
	}

	td,
	th {
		padding: 8px 8px;
	}

	tr {
		border-bottom: 1px solid var(--border-color);
	}

}

a { 
	color: var(--text); 
	transition: color 250ms; 
	&:hover { 
		color: $pto-red; 
		text-decoration: none; 
		&.btn { color: white;}
	} 
}

.table { color: var(--text); }

.f10 { font-size: 10px }
.f12 { font-size: 12px }
.f14 { font-size: 14px }


.logo {
	$logo-color: #f8f9fa;
	$logo-background: #38393a;
	font-size: 16px;
	font-family: 'Roboto condensed', sans-serif;
	display: inline-block;
	color: $logo-color;
	//background-color: $logo-background;
	//overflow: hidden;
	white-space: nowrap;
	.p, .t, .s { height: 26px; display: inline-block; text-align: center; line-height: 26px; border: 0; border-top: 1px solid $logo-color; border-bottom: 1px solid $logo-color; }

	/*.p { background-color: $swim-color; color: rgba(0,0,0,0.5); }
	.t { background-color: $bike-color; color: rgba(0,0,0,0.5); }
	.s { background-color: $run-color; color: rgba(0,0,0,0.5); }
	*/
	.p { border-left: 1px solid $logo-color; padding-left: 6px; border-top-left-radius: 4px; border-bottom-left-radius: 4px; }
	.t { padding-right: 4px; }
	.s { background-color: $logo-color; color: rgba(0,0,0,0.5); color: $logo-background; padding-left: 4px; padding-right: 6px; border-top-right-radius: 4px; border-bottom-right-radius: 4px; }

	.p:after {
		content: 'Pro';
	}
	.t:after {
		content: 'Triathlon';
	}
	.s:after {
		content: 'Stats';
	}
}

#topbar-area {
	background-color: var(--card-color);
	//border-bottom: 1px solid rgb(0, 0, 0, 0.5);
	height: $topbar-height;
	max-height: $topbar-height;
	//overflow: hidden;
	display: flex;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;

	&:empty {
		display: none;
	}

	//top: constant(safe-area-inset-top); /* iOS 11.0 */
	//top: env(safe-area-inset-top); /* iOS 11.2 */

	//box-shadow: 0 0 6px $box-shadow-color;
}


#search-results, #head-to-head-results {
	position: absolute;
	background-color: var(--subsection);
	min-width: 100%;
	width: fit-content;
	top: calc(100% + 4px);
	padding: .375rem .75rem;

	max-height: 70vh;
	overflow-y: auto;

	display: flex;
	flex-direction: column;
	z-index: 1;
	a { 
		white-space: nowrap;
		width: 100%; 
		padding: 4px 40px 4px 8px;
		border-radius: 4px;
		&.selected { background: #58595a; }
		&:focus {  }
	}
	box-shadow: var(--border-color) 0 0 4px;
	&.hide { display: none; }

	@media (max-width: 768px) {
		position: fixed;
		left: 0;
		top: 44px;
		width: 100%;
		box-shadow: none;
		
	}

}

#head-to-head-input { background-color: var(--subsection); }

.topbar {
	width: 100%;
	position: relative;
	margin: 0 15px;

	.world-rankings-logo {
		height: 18px;
	}

	.pto-logo-long {
		height: 32px;
	}

	.search
	{
		width: 150px;
		position: relative;

	}

	.menu 
	{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		display: flex;
		height: 100%;
		justify-content: center;
		a 
		{ 
			font-weight: bold;
			display: flex;
			align-items: center;
			padding: 0 10px; 
			height: 100%;
			width: 100%;
			text-decoration: unset;
			border-radius: 4px;
		}
		@media (max-width: 992px) {
			display: none;
		}

		.submenu {
			position: relative;
			.subs {

				white-space: nowrap; 
				display: flex;
				visibility: hidden;

				transition: opacity 250ms, visibility 250ms;
				opacity: 0;

				width: fit-content;
				height: fit-content;
				position: absolute;
				padding: 20px 0px;
				//background-color: #111;
				color: white;
				left: calc(50% + 0px);
				transform: translateX(calc(-50% + 0px));
				top: 27px;
				a { 
					color: white;
					font-weight: normal;
					white-space: nowrap; 
					padding: 4px 10px;
					justify-content: center;
					//width: fit-content;
					&:hover {
						color: $pto-red;
					}
				}
				&:before {
					content: " ";
					position: absolute;
					left: -5000px;
					width: 10000px;
					height: calc(100% - 8px);
					background-color: #111; //$subsection; //$subsection;
					z-index: -1;
					top: 8px;
				}
				.title {
					text-align: center;
					padding: 0 40px;
					margin: 0 8px;
					//padding-left: 10px;	
					//padding-right: 40px;
					border-bottom: 1px solid var(--border-color);
					//margin-right: 40px; 
					margin-bottom: 4px;
					padding-bottom: 4px;
					font-weight: bold;
				}
			}
			&:hover {
				.subs { 
					opacity: 1; 
					visibility: unset 
				}
				/*a:first-child { 
					background-color: $section;
				}*/
			}
		}
	}
}

#menu-toggle {
	cursor: pointer;
	-webkit-user-select: none;
	user-select: none;
	margin-left: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;

	width: 24px;
	height: 24px;

	i {
		left: 0;
		position: relative;
		height: 2px;
		width: 18px;
		background-color: var(--text);

		transition: background-color 200ms;

		&:before {
			content: " ";
			position: absolute;
			top: -4px;
			background-color: var(--text);
			width: 18px;
			height: 2px;
			transition: transform 200ms, top 200ms;
		}
		&:after {
			content: " ";
			position: absolute;
			top: 4px;
			background-color: var(--text);
			width: 18px;
			height: 2px;
			transition: transform 200ms, top 200ms;
		}
	}

	&.cross {
		i {
			background-color: transparent;
			&:before {
				transform-origin: 50% 50%;
				transform: rotate(-45deg);
				top: 0;
			}
			&:after {
				transform-origin: 50% 50%;
				transform: rotate(45deg);
				top: 0;
			}
		}
	}

}


#side-menu { 
	position: fixed;
	right: 0;
	top: 0;
	height: calc(100vh);

	#side-menu-background
	{
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(0,0,0,0.5);
		z-index: -1;
		opacity: 0;
		visibility: hidden;
		transition: opacity 200ms 200ms, visibility 200ms 200ms;
	}

	.menu {
		transition: right 200ms 200ms;
		position: absolute;
		display: flex;
		justify-content: unset;
		width: 240px; 
		background-color: var(--subsection);  
		flex-direction: column;
		align-items: flex-start;

		padding: 0 15px;
		padding-top: $topbar-height;

		z-index: 10;
		height: 100%;
		a {
			font-weight: bold;
			width: 100%;
			height: 32px;
			display: flex;
			align-items: center;
			padding: 0 20px; 
			text-decoration: unset;
			&:hover {
				color: unset;
				background-color: $section;
				border-radius: 4px;
			}
		}
		right: -240px;
	}


	&.show {
		display: flex;
		.menu {
			right: 0;
		}
		#side-menu-background {
			opacity: 1;
			visibility: visible;
		}

	}
	.submenu {
		display: flex;
		flex-direction: column;
		//margin-left: -20px;
		padding-bottom: 10px;
		.subs {
			padding-left: 40px;
			white-space: nowrap; 
			display: flex;
			flex-direction: column;
			width: fit-content;
			height: fit-content;
			//left: calc(50% + 20px);
			//transform: translateX(calc(-50% + 20px));
			a { 
				font-weight: normal;
				white-space: nowrap; 
				padding: 4px 10px;
				width: fit-content;
				height: 28px;
			}
			.title {
				color: #888;
				padding-right: 40px;
				border-bottom: 1px solid var(--border-color);
				margin-right: 40px; 
				margin-bottom: 4px;
				padding-bottom: 4px;
				font-weight: bold;
			}
		}
		a:first-child { 
		}
	}
	.footer-links {
		width: 100%;
		a {
			height: unset;
			padding: 0 0 4px 0;
			font-size: 12px;
			color: #888;
			font-weight: unset;
			&:hover { background-color: unset; }
		}
	}
}

.main-area {
	flex-grow: 1;
	width: 100%;
}

.main { 
	//min-height: calc(100vh - #{$topbar-height}); 
	margin-top: 0; 
	margin-bottom: 1rem; 
	height: 100%;
	//max-width: 1140px; 
	//margin: 1rem auto; 
	
	//padding-left: 5px;
	//padding-right: 5px;
	/*display: flex;
	flex-direction: column;
	align-items: center;*/
}

#ssr_placeholder:empty { display: none; }
.main:empty { display: none; }
.main-area:empty { display: none; }
.content-area:empty { display: none; }

.main-footer {
	margin-top: $footer-margin;
	height: $footer-height;
	background-color: var(--sectionlight);
	color: #888;
	//padding-top: 20px;
	//padding-bottom: 40px;

	.world-rankings-logo {
		height: 18px;
	}

}

.content { 
	max-width: 1100px;
	margin: 0 auto;
}

.head-nav
{
	display: inline-block;
	margin: 0;
	margin-bottom: 20px;
	width: 100%;
	display: flex;
	//justify-content: center;
	//border-bottom: 1px solid var(--border-color);
	//background-color: $head-nav;
	overflow-x: auto;
	overflow-y: hidden;
	width: 100%;

	a { 
		color: var(--subtext); 
		padding: 8px 16px; 
		margin-right: 4px;
		text-decoration: unset;
		font-weight: bold;
		white-space: nowrap;

		&:hover { color: $pto-red; }
		&[selected] { border-bottom: 2px solid $pto-red; color: $pto-red; }
	}
}



.latest {

	.race-list { 
		display: flex;
		//flex-direction: column;
		//max-height: 700px;
		overflow: hidden;
		flex-wrap: wrap;

		@media (max-width: 992px) {
			max-height: unset;
		}
	}

	.race { 
		/*margin-bottom: 16px;
		border-top: 1px solid rgba(0,0,0,0.2);
		padding-top: 12px;*/
		padding: 8px 16px 8px 0;
		width: calc(50% - 8px);
		border-top: 1px solid var(--border-color);
		margin: 4px 4px;
		
		//background-color: #222;

		//box-shadow: 0 0 6px $box-shadow-color;
		/*margin: 8px;*/
		padding: 12px 8px 12px 4px;

		@media (max-width: 992px) {
			width: 100%;
			padding-right: 0;
			margin: 0;
		}
	}

	.info {
		margin-top: 4px;
		text-decoration: none;
		.racename {
			font-size: 14px;
			font-weight: bold;
		}
		.racename:hover { color: $palette6; }
	}

	.details {
		font-size: 12px;
		color: #888;
	}

	.top3 {
		//font-size: 12px;
		.position { width: 12px; text-align: right; margin-right: 6px; }
		.flag-icon { font-size: 16px; }
		.name-group { display: flex; }
		.athletename { padding: 0 6px; text-transform: uppercase; }
		.athletetime { width: 60px; text-align: right; margin-right: 0px; padding: 0 4px; margin-right: 4px; }
		.athletepoints { width: 60px; text-align: center; padding: 0 0px; background-color: var(--subsection); height: fit-content; }
	}	
	.athlete-pic { 
		width: 90px; height: 90px; 

		@media (max-width: 768px) {
			width: 50px;
			height: 50px;
		}
	}

}

.podiums
{
	text-align: center;
	td:nth-child(2), th:nth-child(2) { text-align: left; }
	td:nth-child(1), th:nth-child(1) { width: 40px; }
	td:nth-child(2), th:nth-child(2) { width: 160px; }
	td:nth-child(4), th:nth-child(4) { width: 50px; }
	td:nth-child(5), th:nth-child(5) { width: 50px; }
	td:nth-child(6), th:nth-child(6) { width: 50px; }
}

.athlete-results
{
	//font-size: 12px;
	text-align: center;

	th { color: #888; font-weight: unset; border-top: 0;}
	td:nth-child(3), th:nth-child(3) { text-align: left; }

	.first-place, .second-place, .third-place { width: 18px; height: 18px; border-radius: 9px; margin: 0 auto; }
	.first-place { background-color: gold; color: darken(gold, 40%); }
	.second-place { background-color: silver; color: darken(silver, 40%); }
	.third-place { background-color: #cd7f32; color: darken(#cd7f32, 40%); }

	//td, th { padding: 3px 2px; vertical-align: middle; }

	.points {
		color: #888;
		font-size: 10px;
		&:after { content: " pts"; }
	}

	@media (max-width: 768px) {
		td:nth-child(4), th:nth-child(4) { display: none; }
		td:nth-child(5), th:nth-child(5) { display: none; }
		td:nth-child(6), th:nth-child(6) { display: none; }
		.points { display: none; }
	}
}

.races-list
{
	td:nth-child(1), th:nth-child(1) { text-align: center; width: 50px; }
	td:nth-child(2), th:nth-child(2) { text-align: center; width: 40px; }
	@media (max-width: 768px) {
		td:nth-child(4), th:nth-child(4) { display: none; }
		td:nth-child(5), th:nth-child(5) { display: none; }
	}
	//td:nth-child(4), th:nth-child(4) { text-align: center; width: 120px; }

}

/*.race-results
{
	//font-size: 12px;
	text-align: center;

	td:nth-child(2), th:nth-child(2) { text-align: left; font-size: 14px; }
	td:nth-child(2) { text-transform: uppercase; }

	.points {
		color: #888;
		font-size: 10px;
		//&:after { content: " pts"; }
	}

	.athlete-pic {
		width: 40px;
		height: 40px;
		font-size: 8px;
	}

	@media (max-width: 768px) {
		td:nth-child(3), th:nth-child(3) { display: none; }
		td:nth-child(4), th:nth-child(4) { display: none; }
		td:nth-child(5), th:nth-child(5) { display: none; }
		.points { display: none; }
		td:nth-child(3), th:nth-child(3) { font-size: unset; }
	}
}
*/
.race-past-years-winners
{
	text-align: center;
	td:nth-child(1), th:nth-child(1) { width: 40px; }
	td:nth-child(2), th:nth-child(2) { width: 60px; }
	td:nth-child(3), th:nth-child(3) { text-align: left; }
	td:nth-child(4), th:nth-child(4) { width: 60px; }
	td:nth-child(5), th:nth-child(5) { text-align: left; }
}


.snapshot-ranking
{
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
	flex-wrap: unset;
	position: relative;
	scroll-snap-type: x mandatory;
	margin: 0 auto;
    width: fit-content;
    max-width: 100%;
	
	&.nations {
		.flag-icon {
			margin-right: 0;
			padding-bottom: 4px;
			font-size: 104px;
			//margin: -2px 0;
			@media (max-width: 768px) {
				font-size: 90px;
			}
		}
	}

	.athlete { 
		scroll-snap-align: start;
		font-size: 12px;
		width: 140px; 
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		margin: 8px 6px;

		box-shadow: 0 0 8px $box-shadow-color;

		color: var(--white);

		position: relative;

		.image-container
		{
			position: relative;
		}

		.info {
			pointer-events: none;
			width: 100%;
			padding: 4px 12px;
			position: absolute;
			bottom: 0px;
			text-align: right;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			
			background-image: linear-gradient(transparent, rgba(0,0,0,0.5));
			text-shadow: 0 0 6px rgba(0,0,0,0.5);

			.rank {
				font-size: 40px;
				line-height: 40px;
				position: relative;
				width: fit-content;
				z-index: 1;
				.hashtag { font-size: 24px; position: absolute; left: -6px; top: -6px; z-index: -1; }
			}
		}

		.info-bottom {
			padding: 8px 4px;
			font-size: 14px;
			width: 100%;
			line-height: 18px;
			height: 3em;
			background-color: var(--info-bottom);
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
		}

		.athlete-pic { 
			width: 140px;
			height: 140px;

			border-radius: 0;
		}


		@media (max-width: 768px) {
			/*padding: 4px 0;
			.athlete-pic { 
				width: 50px;
				height: 50px;
			}
			flex-direction: row;
			width: 50%; 
			text-align: left;
			align-items: start;
			.info {
				padding: 0 4px;
			}*/

			width: 136px;

			.athlete-pic {
				width: 120px;
				height: 120px;
			}

			.info { 
				.rank {
					font-size: 24px;
					line-height: 24px;
					.hashtag {
						font-size: 16px;
						line-height: 16px;
						left: -4px;
						top: -4px;
					}
				}
			}
			.info-bottom {
				font-size: 11px;
				line-height: 11px;
			}

		}

	}

	/*@media (max-width: 768px) {
		flex-direction: column;
		max-height: 320px;
		flex-wrap: wrap;
		&.nations {
			max-height: 275px;
		}
	}*/

}

.athlete-ranking 
{
	//font-size: 12px;
	.entry { display: flex; border-top: 1px solid var(--border-color); padding: 2px 0; align-items: center; }
	.year { width: 40px; text-align: center; }
	.bar { flex-grow: 1; display: flex; align-items: center;}
	.fill { height: 12px; background-color: $palette9; border-radius: 4px; }
	.points { color: #888; padding-left: 2px; font-size: 10px;}
	.rank { width: 50px; text-align: center; }
	.diff { 
		width: 50px; 
		text-align: right; 
		padding-right: 12px;
	}

	.head { 
		color: #888;
		//font-size: 12px;
		border-top: unset;
		.points { font-size: unset; flex-grow: 1; }
	}

	&.swim {
		.fill { background-color: $swim-color;}
	}

	&.bike {
		.fill { background-color: $bike-color;}
	}

	&.run {
		.fill { background-color: $run-color;}
	}

}

.athlete-info
{
	font-size: 14px;
	@media (max-width: 768px) {
		font-size: 12px;
	}

	.info-block
	{
		display: flex;
		@media (max-width: 768px) {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			h1 { text-align: center; }
		}
	}

	.entry { 
		//font-size: 12px;
		display: flex; 
		padding: 1px 0; 
		align-items: center; 
		&.swim {
			.fill { background-color: $swim-color;}
		}

		&.bike {
			.fill { background-color: $bike-color;}
		}

		&.run {
			.fill { background-color: $run-color;}
		}
	}

	.bar { flex-grow: 1; display: flex; align-items: center;  }
	.fill { height: 12px; background-color: $palette9; border-radius: 4px; }
	.points { color: #888; padding-left: 2px; font-size: 10px;}
	.discipline { width: 40px; text-align: right; margin-right: 4px;}

	.ranking { font-weight: bold; color: $section; background-color: var(--text); padding: 1px 8px; border-radius: 8px; }
	.athlete-pic {
		//border-radius: 4px; 
		width: 160px;
		height: 160px;

		@media (max-width: 768px) {
			width: 90px; height: 90px;
		}

	}
	.mini-info {
		display: flex;
		.material-icons-round { font-size: 18px; margin-right: 4px; }
		.item { display: flex; align-items: center; padding-right: 12px; }
	}

	h1 { font-size: 2.5em; }

	.attribute-group
	{
		display: flex;
		@media (max-width: 768px) {
			//flex-direction: column;
			flex-wrap: wrap;
			justify-content: center;
		}
		.attribute {
			display: flex;
			flex-direction: column-reverse;
			align-items: center;
			padding: 0 0.6em;
			@media (max-width: 768px) {
				//flex-direction: row;
				align-items: center;
			}
			.name { 
				font-weight: lighter;
				font-size: 0.9em;
				color: var(--subtext);
				//text-transform: uppercase;
				@media (max-width: 768px) {
					//width: 50%;
				}
			}
			.value {
				font-size: 1.2em;
				//font-weight: bold;
				text-align: top;
				@media (max-width: 768px) {
					//font-size: 20px;
				}
				span {
					font-size: 0.7em;
					font-weight: lighter;				
				}
			}
		}
	}

	.rings {
		display: flex;
		@media (max-width: 768px) {
			justify-content: center;
		}

	}
}

.athlete-with
{
	display: flex;
	font-size: 12px;
	justify-content: center;
	flex-wrap: wrap;
	.athlete { display: flex; flex-direction: column; align-items: center; padding: 2px 8px; }
	.info { display: flex; flex-direction: column; align-items: center; }
	.athlete-pic { width: 80px; height: 80px; margin: 4px 8px; }
	.number { 
		color: $palette9; 
		&.below { color: $palette0; }
	}

	@media (max-width: 768px) {
		//.athlete-pic { width: 50px; height: 50px;  }
		//.athlete { flex-direction: row; width: 100%; justify-content: center;}
		//.info { flex-direction: column; justify-content: start; }
	}

}

.social
{
	display: flex;
	flex-wrap: wrap;
	height: 20px;
	@media (max-width: 768px) {
		justify-content: center;
	}
	a { display: flex; align-items: center; /*font-size: 12px;*/ }
	.social-link {
		margin: 0 6px;
		i {
			font-size: 20px;
		}
		img { 
			width: 20px; 
			margin-right: 2px; 
			filter: grayscale(50%);
			&:hover { filter: unset; }
		}
	}
	&:not(:empty)
	{
		&:before { 
			//content: "Socials";
			font-weight: bold;
			margin-right: 8px;		
		}
	}
}


#dialog-submit-athlete-pic {
	.body { 
		-webkit-user-select: none;
		user-select: none; 
	}
}
.edit-image 
{
	cursor: pointer;
	.transform-image { 
		position: absolute; 
		img {  }
	}
	.mask { 
		box-shadow: 0 0 0 99999px rgba(0,0,0,0.8); 
		background-color: transparent; 
		z-index: 2; 
		outline: 6px solid $palette6; 
		pointer-events: none; 
		position: relative; 

		&.danger { outline: 6px solid $pto-red; }
	}
}

.edit-image
{
	height: calc(100vh - 300px);
	width: calc(80vw - 40px);
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;

	img { 
		pointer-events: none; 
		user-select: none; 
		-webkit-user-select: none;
	}
	//width: fit-content;
	/*img {
		max-width: 80vw;
		max-height: 80vh;
	}*/

	.resize-drag {
		background-color: rgba(91, 129, 243, 0.64);
		color: white;
		font-size: 20px;
		font-family: sans-serif;
		border-radius: 8px;
		padding: 20px;
		touch-action: none;

		position: absolute;

		box-sizing: border-box;
		z-index: 1;
		pointer-events: all;
	}
}

.athlete-pic { 
	width: 120px; 
	height: 120px;
	//border-radius: 4px; 
	text-align: center;
	font-size: 10px;
	display: block;
	color: #888;
	position: relative;
	overflow: hidden;
}


.athlete-pic-group {
	position: relative;
	display: table;
	.nopic {
		position: absolute;
		top: 0;
		left: 0;
		width: calc(100%);
		height: calc(100%);

		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid var(--border-color);
		font-size: 10px;
		color: var(--border-color);
		padding: 4px;
		background-color: var(--background);
	}
	.nopic:after { content: "No Picture" }
}



.ox-modal{
	display: none;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: #000000d0;
	position: fixed;
	//display: flex;
	justify-content: center;
	align-items: center;
	z-index: 200;

	/*@media (max-width: 768px) {
		padding-top: 20px;
		align-items: start;
	}*/

	&.show { display: flex; }

	.dialog {
		//width: 30vw;
		//min-width: 500px;
		min-width: 300px;
		max-width: 80vw;
		background-color: #282828;
		border-radius: 0.5rem;
		overflow: hidden;
		font-size: 1.0rem;
		max-height: 100vh;
		display: flex;
		flex-direction: column;


		&[large="true"] {
			min-width: 800px;
		}

		.header{
			user-select: none;
			-webkit-user-select: none;
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: #282828;
			padding: 0.75rem 1.5rem;
			color: white;
		}
		.close-modal {
			cursor: pointer;
		}

		.body {
			flex-shrink: 1;
			overflow-y: auto;
		}

		.body{
			padding: 0.75rem 1.5rem;
		}

		.footer{
			padding: 1.0rem 1.5rem;
		}

		.footer {
			display: flex;
			justify-content: stretch;
		}

		input[type="text"], textarea, input[type="date"] {
			border-radius: 0.25rem;
			padding: 0.5rem;
			outline: none;
			resize: none;
			color-scheme: dark;

			&:focus{
				border-color: $palette6;
				box-shadow: none;
			}
		}
	}
}

.admin-contributions
{
	td:nth-child(5), th:nth-child(5) { width: 100px; }
	td:nth-child(6), th:nth-child(6) { width: 100px; }
}

.standing {
	table { width: 100%; }
	th { font-weight: unset; color: #888; }
	td { border-top: 1px solid var(--border-color); }
	td:nth-child(1), th:nth-child(1) { text-align: center; width: 40px; }
	td:nth-child(3), th:nth-child(3) { text-align: center; width: 60px; }
	border-radius: 4px;
}

.athlete-list {
	&.table { 
		th { font-weight: unset; color: #888; }
		td:nth-child(1), th:nth-child(1) { text-align: center; width: 60px; }
		td:nth-child(3), th:nth-child(3) { text-align: center; width: 60px; }
		td:nth-child(4), th:nth-child(4) { text-align: center; width: 80px; }
		td:nth-child(5), th:nth-child(5) { text-align: center; width: 60px; }
		td:nth-child(6), th:nth-child(6) { text-align: center; width: 60px; }
		td:nth-child(7), th:nth-child(7) { text-align: center; width: 60px; }
	}	
}

.ranking-number
{
	font-family: Avenir Next LT W04 Heavy Cn It, sans-serif;
	text-transform: uppercase;

	&:before {
		content: "#";
		font-size: 20px;
		position: absolute;
		left: -6px;
		top: -6px;
		color: $pto-red;
	}	

	font-size: 30px;
	line-height: 30px;
	position: relative;
	width: fit-content;
	margin: 0 auto;
}

.rankings {

	width: 100%; 
	display: flex;
	flex-direction: column;

	.thead, .trow {
		display: flex;

		& > div {
			margin: 2px;
			padding: 2px;
			display: flex; 
			justify-content: center; 
			align-items: center;

		
			&:nth-child(1) { text-align: center; width: 70px; flex-shrink: 0; }
			&:nth-child(2) { text-align: center; width: 40px; flex-shrink: 0; }

			&:nth-child(3) { 
				flex-grow: 1; 
				justify-content: flex-start; 
			}

			&:nth-child(4) { text-align: center; width: 70px; flex-shrink: 0; }
		}
	}

	.trow {
		& > div {
			background-color: var(--subsection);
			&:nth-child(3) { 
				font-family: Avenir Next LT W04 Heavy Cn It, sans-serif;
				text-transform: uppercase;
				font-size: 20px;

				& .name {
					margin: 0 8px;  
				}

				@media (max-width: 768px) {
					font-size: 16px;
					flex-direction: column;
					
					& .name {
						margin: 0 4px;  
						text-align: center;
					}
				}
			}
			&:nth-child(4) { font-size: 20px; background-color: $pto-red; }
		}
	}

	.athlete-pic {
		width: 50px;
		height: 50px;
	}

	&.table { 
		width: 100%; 

		display: flex;


		/*td {  
			background-color: var(--subsection);
			border: 4px solid var(--background);
		}

		td:nth-child(1), th:nth-child(1) { text-align: center; width: 40px; }
		td:nth-child(2), th:nth-child(2) { text-align: center; width: 40px; color: #888; }
		td:nth-child(3), th:nth-child(3) { text-align: center; width: 40px; }
		td:nth-child(4), th:nth-child(4) { padding-left: 8px; }
		td:nth-child(5), th:nth-child(5) { text-align: center; width: 70px; }

		td:nth-child(4) { text-transform: uppercase; }

		td:nth-child(5) { background-color: $pto-red; color: $pto-white; position: relative; }
		td:nth-child(5):before {
			content: " ";
			width: 24px;
			height: 100%;
			background-color: $pto-red;
			transform: skewX(-24deg);
			position: absolute;
			left: -12px;
			top: 0;
		}*/
	}

}

.up { 
	color: $palette9;
	position: relative; 
	&:after {
		top: 4px;
		left: 100%;
		margin-left: 2px;
		position: absolute;
		content: " ";
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 3px 6px 3px;
		border-color: transparent transparent $palette9 transparent;				
	}
	&.reverse { 
		color: $palette15; 
		&:after {
			border-color: transparent transparent $palette15 transparent;				
		}
	}
}
.down { 
	color: $palette15; 
	position: relative; 
	&:after {
		top: 6px;
		left: 100%;
		margin-left: 2px;
		position: absolute;
		content: " ";
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 6px 3px 0 3px;
		border-color: $palette15 transparent transparent transparent;				
	}
	&.reverse { 
		color: $palette9; 
		&:after {
			border-color: $palette9 transparent transparent transparent;				
		}
	}
}


#form-rankings, #form-athlete-list, #form-alltime
{
	select {
		max-width: 130px;
	}
}



.ring_progress {
    width: 60px;
    height: 70px;
    margin-right: 10px;

	.meter, .value {
		fill: none;
		transform: rotate(-90deg);
	}

	.meter {
		stroke: var(--ring-color);
	}

	.value {
		stroke: $overall-color;
		stroke-linecap: round;
	}
	.percent { fill: $overall-color; font-size: 28px; font-weight: bold; }
	.title { fill: $overall-color; font-size: 24px; }

	&.swim { 
		.value { stroke: $swim-color; }
		.percent, .title { fill: $swim-color; }
	}
	&.bike { 
		.value { stroke: $bike-color; }
		.percent, .title { fill: $bike-color; }
	}
	&.run { 
		.value { stroke: $run-color; }
		.percent, .title { fill: $run-color; }
	}

}



.graph-container {
	max-width: 700px;
	margin: 0 auto;
	.graph-resizer {
		background-color: var(--background);
		position: relative;
		width: 100%;
		padding-top: 30%;
		svg{
			position: absolute;
			top: 0;
		}
	}

	.grid {
		text {
			font-size: 12px;
			fill: #aaa;
			alignment-baseline: middle;
			text-anchor: end;
		}		
	}

	.swim {
		path {
			fill: none;
			stroke: $swim-color;
			stroke-width: 2;
		}
		circle { fill: $swim-color; stroke-width: 2; stroke: var(--background);  }

		&.men { path { stroke: $swim-color; } }
		&.women { path { stroke: $swim-color; } }
		&.area {
			path {
				fill: $swim-color;
				opacity: 0.5;
				stroke: none;
				stroke-width: 0;
			}
			&.men { path { fill: $swim-color; } }
			&.women { path { fill: $swim-color; } }
		}
	}

	.bike {
		path {
			fill: none;
			stroke: $bike-color;
			stroke-width: 2;
		}
		circle { fill: $bike-color; stroke-width: 2; stroke: var(--background);  }

		&.men { path { stroke: $bike-color; } }
		&.women { path { stroke: $bike-color; } }
		&.area {
			path {
				fill: $bike-color;
				opacity: 0.5;
				stroke: none;
				stroke-width: 0;
			}
			&.men { path { fill: $bike-color; } }
			&.women { path { fill: $bike-color; } }
		}
	}

	.run {
		path {
			fill: none;
			stroke: $run-color;
			stroke-width: 2;
		}
		circle { fill: $run-color; stroke-width: 2; stroke: var(--background);  }

		&.men { path { stroke: $run-color; } }
		&.women { path { stroke: $run-color; } }
		&.area {
			path {
				fill: $run-color;
				opacity: 0.5;
				stroke: none;
				stroke-width: 0;
			}
			&.men { path { fill: $run-color; } }
			&.women { path { fill: $run-color; } }
		}
	}

	.overall {
		path {
			fill: none;
			stroke: $overall-color;
			stroke-width: 2;
		}

		&.men { path { stroke: $palette5; } }
		&.women { path { stroke: $palette1; } }

		&.area {
			path {
				fill: $overall-color;
				opacity: 0.5;
				stroke: none;
				stroke-width: 0;
			}
			&.men { path { fill: $palette5; } }
			&.women { path { fill: $palette1; } }
		}

		circle { fill: $overall-color; stroke-width: 2; stroke: var(--background);  }
	}

	.labels {
		
		text { text-anchor: middle; fill: #aaa; font-size: 12px; }
	}

	.mouse {
		.popup { 
			box-shadow: var(--border-color) 0 0 4px;
			filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
			font-size: 12px;
			display: none; 
			rect { fill: #333; }
			.points { fill: white; font-size: 12px; font-weight: unset; text-anchor: middle; }
			.rank { fill: white; text-anchor: end; }
		}
		text { 
			pointer-events: none; 
			font-weight: bold;

			&.year { fill: #888; text-anchor: middle; }

			&.swim { fill: $swim-color; }
			&.bike { fill: $bike-color; }
			&.run { fill: $run-color; }
			&.overall { fill: $overall-color; }
		}
		rect {
			fill: transparent;
		}
		.hover:hover { 
			.popup { display: block; }
		}
	}

}


.race-points
{
	width: unset;
	td { padding: 0 80px 0 4px; } 
}

.anchor {
	padding-top: calc(40px + 1.5rem);
	margin-top: -40px;
}

.stats-green { background-color: $palette9; }
.stats-blue { background-color: $palette6; }
.stats-pink { background-color: $palette1; }

.head-vs {
    position: sticky;
    top: calc(44px);
    background-color: var(--background);
    z-index: 1;
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 1.5rem;
}

.compare-row
{
	.best {
		background-color: $palette9; 
		border-radius: 4px;
	}

	.hth-progress
	{
		width: 100%;
		height: 12px;
		background-color: #444;
		position: relative;
		border-radius: 4px;
		overflow: hidden;

		div {
			position: absolute;
			height: 12px;
		}

		&.left
		{
			div {
				right: 0;
				background-color: $palette6;
			}
		}
		&.right
		{
			div {
				background-color: blue;
				background-color: $palette13;
			}
		}
	}
}

.races-compare
{
	td:nth-child(1), th:nth-child(1) { text-align: center; width: 70px; }
	td:nth-child(3), th:nth-child(3) { text-align: center; width: 70px; }
}

.hth-info
{
	td:nth-child(1), th:nth-child(1) { text-align: right; width: 41.666%; }
	td:nth-child(2), th:nth-child(2) { text-align: center; width: 16.6666%; }
	td:nth-child(3), th:nth-child(3) { text-align: left; width: 41.666%; }
}

.head-to-head-list
{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	img { width: 80px; height: 80px; }
	
	.athlete { 
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 12px;
	}
}

.youtube-feed{
	max-width: 100%;
	display: flex;
	font-family: Roboto, Arial, sans-serif;
	overflow-x: auto;
	margin: 24px auto;
	scroll-snap-type: x mandatory;

	font-size: 13px;

	.youtube-video {
		scroll-snap-align: start;
		margin-right: 8px;
		text-decoration: none !important;
		color: #888 !important;

		img {
			width: 200px;
			height: 112px;
			object-fit: cover;
			margin-bottom: 4px;
		}

		.youtube-title {
			width: 175px;
			text-overflow: ellipsis;
			white-space: normal;
			-webkit-line-clamp: 2;
			overflow: hidden;
			-webkit-box-orient: vertical;
		    display: -webkit-box;
		}
	}
}

.instagram-feed{
	max-width: 100%;
	display: flex;
	font-family: Roboto, Arial, sans-serif;
	overflow-x: auto;
	margin: 24px auto;
	scroll-snap-type: x mandatory;

	font-size: 13px;

	.instagram-image {
		scroll-snap-align: start;
		margin-right: 8px;
		text-decoration: none !important;
		color: #888 !important;

		img {
			width: 160px;
			height: 160px;
			object-fit: cover;
			margin-bottom: 4px;
		}

		.instagram-title {
			width: 150px;
			text-overflow: ellipsis;
			white-space: normal;
			-webkit-line-clamp: 2;
			overflow: hidden;
			-webkit-box-orient: vertical;
		    display: -webkit-box;
		}
	}
}


.flag-icon { background-size: cover !important; }
.flag-icon-collins-int { background-image: url(/assets/int-flag.svg); }
.flag-icon-collins-eu { background-image: url(/assets/eur-flag.svg); }
.flag-icon-collins-us { background-image: url(/assets/usa-flag.svg); }


.collinscup-teams
{
	.vs {
		display: flex;
		align-items: center;
		padding: 0 1rem;
		font-size: 1.5rem;
	}
}

#collinscup-athletes
{
	/*.snapshot-ranking {
		width: 100%;
		justify-content: space-around;
	}*/
	/*.athlete {
		width: 100px;
	}
	.athlete-pic {
		width: 100px;
		height: 100px;
	}
	.info-bottom {
		font-size: 11px;
		line-height: 11px;
	}

	.rank {
		font-size: 24px;
		line-height: 24px;
		.hashtag {
			font-size: 16px;
			line-height: 16px;
			left: -4px;
			top: -4px;
		}
	}*/

	.team {
		background-color: #333;
	}
	.snapshot-ranking {
		flex-direction: column;
		//flex-wrap: wrap;
		justify-content: center;
		margin: 0 4px;
	}
	.athlete {
		width: 260px;
		flex-direction: row;
		margin: 4px 6px;
		height: fit-content;
	}

	.image-container {
		display: flex;
		flex-direction: row-reverse;
	}

	.info { 
		position: unset;
		flex-direction: column;
		align-items: center;
		background-image: unset;
		text-shadow: unset;
		text-align: unset;
		width: 70px;
		justify-content: center;
		background-color: var(--info-bottom);

		.rank {
			font-size: 24px;
			line-height: 24px;
			.hashtag {
				font-size: 16px;
				line-height: 16px;
				left: -4px;
				top: -4px;
			}
		}
	}
	.info-bottom {
		height: 100%;
		//width: 200px;
		//font-size: 11px;
		//line-height: 11px;
	}

	.athlete-pic {
		width: 60px;
		height: 60px;
	}

	.team-name {
		justify-content: space-between;
		margin: 0 16px;
		position: relative;
		.flag-icon {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 0;
			filter: brightness(0.4);
		}
	}

}

.sponsor
{
	width: 120px;
	height: 120px;
	padding: 8px 16px;
	position: relative;
	img { 
		opacity: 0.8;
		width: 100%; 
		object-fit: contain; 
		height: 100%;
		
		&:hover { opacity: 1; }

		&[alt]:after {  
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			font-size: 16px;
			background-color: var(--background);
			content: attr(alt);
		}

	}
}


.account {
	width: 100%;
	padding: 0 1.5rem;
	max-width: 400px;
}


