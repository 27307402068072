.custom-scrollbar
{
	&.scroll-x { margin-bottom: -10px; }
	
	&::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	&::-webkit-scrollbar-button { width: 0; height: 0; }
	&::-webkit-scrollbar-thumb {
		background: transparent;
		border: 0px none #ffffff;
		border-radius: 50px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #303030;
	}
	&::-webkit-scrollbar-thumb:active {
		background: #303030;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
		border: 0;
	}
	&::-webkit-scrollbar-track:hover { background: transparent; }
	&::-webkit-scrollbar-track:active { background: transparent; }
	&::-webkit-scrollbar-corner { background: transparent; }  

	&:hover {
		&::-webkit-scrollbar-thumb {
			background: rgba(255,255,255,0.1);
		}
	}

}
